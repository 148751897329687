@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

html {
  /* Apply styles to all scrollbars */

  * {
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #4b5563 #f3f4f6; /* Default (light mode): dark thumb on light track */
  }

  /* For Chrome, Edge, and Safari */

  *::-webkit-scrollbar {
    width: 8px; /* Scrollbar width */
    height: 8px; /* Horizontal scrollbar height */
  }

  *::-webkit-scrollbar-track {
    background: #f3f4f6; /* Default (light mode): light gray track */
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #4b5563; /* Default (light mode): dark gray thumb */
    border-radius: 10px;
    border: 2px solid transparent;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #374151; /* Darker gray on hover */
  }

  /* Handle on active (while being dragged) */

  *::-webkit-scrollbar-thumb:active {
    background-color: #111827;
  }

  &.dark {
    * {
      scrollbar-color: #9ca3af #1f2937; /* Dark mode: light thumb on dark track */
    }

    *::-webkit-scrollbar-track {
      background: #1f2937; /* Dark mode: dark gray track */
    }

    *::-webkit-scrollbar-thumb {
      background-color: #9ca3af; /* Dark mode: light gray thumb */
    }

    *::-webkit-scrollbar-thumb:hover {
      background-color: #d1d5db; /* Lighter gray on hover in dark mode */
    }

    *::-webkit-scrollbar-thumb:active {
      background-color: #e5e7eb; /* Even lighter when dragging in dark mode */
    }
  }
}

/* Hide the browser's native eye icon on password fields */
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear,
input[type='password']::-webkit-clear-button,
input[type='password']::-webkit-reveal-button {
  display: none;
}

sup {
  color: red;
}
